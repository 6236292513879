import React from "react";
import "./App.css";
import { Helmet } from "react-helmet";

function App() {
  return (
    <>
      <Helmet>
        <link rel="icon" href="/favicon.ico" />
        <title>OnlyRats - The Mischief Zone</title>
        <meta name="description" content="ETA: 👽🐀" />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content="https://onlyrats.cool/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="OnlyRats - The Mischief Zone " />
        <meta property="og:description" content="ETA: 👽🐀" />
        <meta property="og:image" content="/og_image.jpg" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="onlyrats.cool" />
        <meta property="twitter:url" content="https://onlyrats.cool/" />
        <meta name="twitter:title" content="OnlyRats - The Mischief Zone " />
        <meta name="twitter:description" content="ETA: 👽🐀" />
        <meta name="twitter:image" content="/og_image.jpg" />
      </Helmet>

      <div className="App">
        <div id="background" />
        <div id="cover" className="float" />
      </div>
    </>
  );
}

export default App;
